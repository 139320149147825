import React from 'react';

function HomePage() {
  // Inline styles for responsiveness
  const iframeStyle = {
    width: '70%', // Take full width of the container
    height: '100vh', // Use 100% of the viewport height
    border: 'none' // Optional: remove the border for a cleaner look
  };

  const containerStyle = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh', // Make the container full height of the viewport
    padding: '20px' // Add some padding around the iframe
  };

  return (
    <div>
      <h1 style={{ textAlign: 'center' }}>Welcome to Drone Threat Modeling Project</h1>
      <div style={containerStyle}>
        <iframe 
          style={iframeStyle}
          src="https://drive.google.com/file/d/1CixlVtlg9rn7KukbwuHb4jLdsfNsLoXH/preview"
          allow="autoplay"
          // title="Drone Threat Modeling Project Manual"
        ></iframe>
      </div>
    </div>
  );
}

export default HomePage;