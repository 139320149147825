import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import HomePage from './HomePage';
import AnnotationInterface from './AnnotationInterface';
// import DroneAnimations from './DroneAnimations';
import Navbar from './Navbar';

function App() {
  return (
    <Router>
      <Navbar />
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/annotation" element={<AnnotationInterface />} />
        {/* <Route path="/animation" element={<DroneAnimations />} /> */}
      </Routes>
    </Router>
  );
}

export default App;