import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Typography } from '@mui/material';

// Define your data based on the image you provided
const areasData = [
    { area: "National Government Leadership Buildings", medianScore: 5 },
    { area: "Security Installations (e.g., military, police)", medianScore: 5 },
    { area: "Electricity/Natural Gas Plants", medianScore: 5 },
    { area: "Sanitation & Water Plants", medianScore: 4.5 },
    { area: "National Government Operational Buildings", medianScore: 4 },
    { area: "Hospitals", medianScore: 4 },
    { area: "Industrial & Hazardous Materials Areas", medianScore: 4 },
    { area: "Transportation Hubs (airports, train stations, etc.)", medianScore: 4 },
    { area: "Other High Population Density Areas", medianScore: 4 },
    { area: "Tourist Sites", medianScore: 3.5 },
    { area: "City Local/Municipality Buildings", medianScore: 3 },
    { area: "Financial Districts", medianScore: 3 },
    { area: "Shopping & Entertainment Areas", medianScore: 3 },
    { area: "Sports Arenas", medianScore: 3 }
];

// Function to determine color based on score
const getColorForScore = (score) => {
    // This is a simple example; you might want to scale your colors differently
    if (score >= 90) return '#ff6868'; // red
    if (score >= 70) return '#ffbf68'; // orange
    if (score >= 40) return '#ffff68'; // yellow
    return '#68ff68'; // green
};

// The Table component
const ScoreTable = () => {
    return (
        <>
            <Typography variant="h6" gutterBottom component="div" style={{ marginTop: '20px' }}>
                <center>Suggested Median Values from Expert Survey</center>
            </Typography>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 650 }} size="small" aria-label="simple table">
                    <TableHead>
                        <TableRow>
                            <TableCell><b>Type of Area/Establishment</b></TableCell>
                            <TableCell align="right"><b>Median Value</b></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {areasData.map((row) => (
                            <TableRow
                                key={row.area}
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                            >
                                <TableCell component="th" scope="row">
                                    {row.area}
                                </TableCell>
                                <TableCell align="right" style={{ backgroundColor: getColorForScore(row.medianScore * 20) }}>
                                    {row.medianScore * 20}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </>
    );
};

export default ScoreTable;