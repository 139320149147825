// Navbar.js

import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';

function Navbar() {
  return (
    <AppBar position="static" style={{ backgroundColor: '#4E2A84' }}> {/* Inline styling for purple background */}
      <Toolbar>
        <Button color="inherit" component={RouterLink} to="/">
          Home
        </Button>
        <Button color="inherit" component={RouterLink} to="/annotation">
          Annotation
        </Button>
        {/* <Button color="inherit" component={RouterLink} to="/animation">
          Analytics
        </Button> */}
         {/* Place your image here */}
         {/* <img align="right" src="nsail_logo.png" alt="NSAIL Logo" height="40px" /> */}
      </Toolbar>
    </AppBar>
  );
}

export default Navbar;
