import React, { useState } from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

const PasswordComponent = () => {
    const [inputPassword, setInputPassword] = useState('');
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const correctPassword = "nsail#"; // Replace with your password

    const handleSubmit = (e) => {
        e.preventDefault();
        if (inputPassword === correctPassword) {
            setIsAuthenticated(true);
        } else {
            alert('Incorrect password!');
        }
    };

    return (
        <div>
            {!isAuthenticated ? (
                <center>
                    <form onSubmit={handleSubmit}>
                        <label>
                            Enter password:
                            <input 
                                type="password" 
                                value={inputPassword} 
                                onChange={(e) => setInputPassword(e.target.value)} 
                            />
                        </label>
                        <button type="submit">Submit</button>
                    </form>
                </center>
            ) : (
                <App />
            )}
        </div>
    );
};

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.StrictMode>
        <PasswordComponent />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
