// AnnotationInterface.js
import React, { useState, useEffect } from 'react';
import L from 'leaflet';
import { MapContainer, TileLayer, FeatureGroup } from "react-leaflet";
import { EditControl } from "react-leaflet-draw";
import { DraftControl } from "react-leaflet-draft";
import axios from 'axios';
// import {Box, Grid, Input, Button } from '@mui/joy';
// import { Select, MenuItem} from '@mui/material';
// import {Select, MenuItem, TextField} from '@mui/material';
// import { Box, Button, TextField, Select, MenuItem, Grid, FormControl, InputLabel } from '@mui/material';
import { Box, Button, Select, MenuItem, Grid, FormControl, InputLabel } from '@mui/material';
import FormatShapesIcon from '@mui/icons-material/FormatShapes';
import ScoreTable from './ScoreTable'; // Adjust the path according to your file structure

import 'leaflet/dist/leaflet.css';

delete L.Icon.Default.prototype._getIconUrl;

L.Icon.Default.mergeOptions({
    iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
    iconUrl: require("leaflet/dist/images/marker-icon.png"),
    shadowUrl: require("leaflet/dist/images/marker-shadow.png")
});

function AnnotationInterface() {
    const [cities, setCities] = useState([]);
    const [selectedCity, setSelectedCity] = useState('');
    const [cityCenters, setCityCenters] = useState({});  // Stores city centers
    const [cityCenter, setCityCenter] = useState({ lat: 0, lng: 0 });
    const [nodes, setNodes] = useState([]);
    // const [edges, setEdges] = useState([]);
    const [shapes, setShapes] = useState([]);
    const [initialShapes, setInitialShapes] = useState([]);
    const [annotationValue, setAnnotationValue] = useState('');
    const [showForm, setShowForm] = useState(false);
    const [selectedRectangle, setSelectedRectangle] = useState(null);
    const [formData, setFormData] = useState([]);

    const annotations = React.useRef([]);
    const mapRef = React.useRef(null);

    // Initialize formData with 10 rows
    useEffect(() => {
        const initialFormData = Array(10).fill().map((_, index) => ({
            dayType: '',
            startTime: 0,
            endTime: 0,
            value: 0,
            RowIndex: index
        }));
        setFormData(initialFormData);
    }, []);

    const handleFormDataChange = (index, field, value) => {
        const updatedFormData = [...formData];
        updatedFormData[index][field] = value;
        setFormData(updatedFormData);
    };

    // useEffect(() => {
    //     axios.get('/cities').then(response => {
    //         setCities(response.data);
    //     }).catch(error => console.error("Error fetching cities:", error));
    // }, []);
    useEffect(() => {
        axios.get('/cities').then(response => {
            setCities(response.data.cities);
            setCityCenters(response.data.centers);  // Assuming centers are provided in the response
        }).catch(error => console.error("Error fetching cities:", error));
    }, []);

    useEffect(() => {
        if (selectedCity) {
            const center = cityCenters[selectedCity];
            setCityCenter(center);
            axios.get(`/nodes/${selectedCity}`).then(response => setNodes(response.data)).catch(error => console.error("Error fetching nodes:", error));
            // axios.get(`/edges/${selectedCity}`).then(response => setEdges(response.data)).catch(error => console.error("Error fetching edges:", error));
            axios.get(`/shapes/${selectedCity}`).then(response => setShapes(response.data)).catch(error => console.error("Error fetching shapes:", error));
            axios.get(`/shapes/${selectedCity}`).then(response => setInitialShapes(response.data)).catch(error => console.error("Error fetching shapes:", error));
            // Removed annotations fetching as it's not used in this interface
            axios.get(`/annotations/${selectedCity}`).then(response => annotations.current = response.data).catch(error => console.error("Error fetching annotations:", error));
        }
    }, [selectedCity]);

    useEffect(() => {
        if (!selectedCity || !mapRef.current) {
            return;
        }

        // const map = L.map('map').setView([38.887072, -77.035559], 20); // Default view, you might want to change
        const map = L.map('map').setView([cityCenter.lat, cityCenter.lng], 13);
        mapRef.current = map;

        L.tileLayer('https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png', {
            attribution: 'Map data © <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
            maxZoom: 20,
        }).addTo(map);

        var drawnItems = new L.FeatureGroup();
        map.addLayer(drawnItems);

        L.EditToolbar.Delete.include({
            removeAllLayers: false
        });

        var drawControl = new L.Control.Draw({
            draw: {
                polyline: false,
                marker: false,
                circlemarker: false,
                circle: false
            },
            edit: {
                featureGroup: drawnItems,
            }
        });

        map.addControl(drawControl);

        // Event listeners for draw created, edited, deleted
        // ...

        shapes.forEach((shape, index) => {

            var geojsonLayer = L.geoJSON(shape, {
                featureGroup: drawnItems,
            });

            const annotation = annotations.current.find(a => a.shapeId === shape.id);

            let popupContent = `<b>Selected Region</b>`;

            // if (annotation) {
            //     popupContent = `<b>Value of Region:</b> ${annotation.value}`;
            // }

            geojsonLayer.eachLayer(function (layer) {

                layer.bindPopup(popupContent).openPopup();

                drawnItems.addLayer(layer);
                layer.on('click', async function (e) {
                    setSelectedRectangle(layer);
                    const shapeId = shape.id; //getShapeIdByShape(shapes, shape);
                    // console.log(shapeId);
                    // const annotation = annotations.current.find(a => a.shapeId === shapeId);
                    // console.log(annotation);
                    // setAnnotationValue(annotation ? annotation.value : '');

                    // Fetch annotation details for the clicked shape
                    try {
                        const response = await axios.get(`/getAnnotationDetails/${shapeId}`);
                        const annotationDetails = response.data;

                        // Check if annotation details exist and update formData accordingly
                        if (annotationDetails.length > 0) {
                            const updatedFormData = annotationDetails.map(detail => {
                                // Split TimeRange into startTime and endTime
                                const [startTime, endTime] = detail.TimeRange.split('-').map(time => parseInt(time, 10));
                
                                return {
                                    dayType: detail.DayType,
                                    startTime: startTime, // Set startTime from split
                                    endTime: endTime, // Set endTime from split
                                    value: detail.Value,
                                    RowIndex: detail.RowIndex
                                };
                            });
                            setFormData(updatedFormData);
                        } else {
                            // Reset formData to initial state with default values for 10 rows
                            const initialFormData = Array(10).fill().map((_, index) => ({
                                dayType: '',
                                startTime: 0,
                                endTime: 0,
                                value: 0,
                                RowIndex: index
                            }));
                            setFormData(initialFormData);
                        }
                    } catch (error) {
                        console.error("Error fetching annotation details:", error);
                    }

                    setShowForm(true);
                });
            });
        });

        map.on(L.Draw.Event.CREATED, function (e) {
            var layer = e.layer;
            const shape = layer.toGeoJSON();

            axios.post(`/shapes/${selectedCity}`, shape)
                .then((response) => {
                    const shapeId = response.data.id;
                    shape.id = shapeId
                    setShapes([...shapes, shape])
                    annotations.current = ([...annotations.current, { shapeId: shapeId, value: '' }]);
                })
                .catch((error) => {
                    console.error(error);
                });

            const geojsonLayer = L.geoJSON(shape);

            let popupContent = `<b>Selected Region</b>`;

            geojsonLayer.eachLayer(function (layer) {

                layer.bindPopup(popupContent).openPopup();
                
                drawnItems.addLayer(layer);
                layer.on('click', async function (e) {
                    setSelectedRectangle(layer);
                    const shapeId = shape.id; //getShapeIdByShape(shapes, shape);
                    // console.log(shapeId);
                    // const annotation = annotations.current.find(a => a.shapeId === shapeId);
                    // console.log(annotation);
                    // setAnnotationValue(annotation ? annotation.value : '');

                    // Fetch annotation details for the clicked shape
                    try {
                        const response = await axios.get(`/getAnnotationDetails/${shapeId}`);
                        const annotationDetails = response.data;

                        // Check if annotation details exist and update formData accordingly
                        if (annotationDetails.length > 0) {
                            const updatedFormData = annotationDetails.map(detail => {
                                // Split TimeRange into startTime and endTime
                                const [startTime, endTime] = detail.TimeRange.split('-').map(time => parseInt(time, 10));
                
                                return {
                                    dayType: detail.DayType,
                                    startTime: startTime, // Set startTime from split
                                    endTime: endTime, // Set endTime from split
                                    value: detail.Value,
                                    RowIndex: detail.RowIndex
                                };
                            });
                            setFormData(updatedFormData);
                        } else {
                            // Reset formData to initial state with default values for 10 rows
                            const initialFormData = Array(10).fill().map((_, index) => ({
                                dayType: '',
                                startTime: 0,
                                endTime: 0,
                                value: 0,
                                RowIndex: index
                            }));
                            setFormData(initialFormData);
                        }
                    } catch (error) {
                        console.error("Error fetching annotation details:", error);
                    }

                    setShowForm(true);
                });
            });

        });

        map.on('draw:edited', function (e) {
            var layers = e.layers;
            // console.log(layers)

            layers.eachLayer(function (layer) {

                const shape = layer.toGeoJSON();
                // console.log(shape);

                const shapeId = shape.id; //getShapeIdByShape(shapes, shape);

                axios.put(`/shapes/${selectedCity}/${shapeId}`, shape)
                    .then(() => {
                        setShapes(shapes.map((s) => {
                            if (s.id === shapeId) {
                                return shape;
                            }
                            return s;
                        }));
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                // const popupContent = `<b>Value of Region is Empty</b>`;
                // layer.bindPopup(popupContent).openPopup();

                // Remove the annotation for the edited shape
                annotations.current = annotations.current.filter(a => a.shapeId !== shapeId);
            });
        });

        map.on('draw:deleted', function (e) {
            var layers = e.layers;
            layers.eachLayer(function (layer) {

                const shape = layer.toGeoJSON();
                const shapeId = shape.id; //getShapeIdByShape(shapes, shape);
                axios.delete(`/shapes/${selectedCity}/${shapeId}`)
                    .then(() => {
                        setShapes(shapes.filter((s) => s.id !== shapeId));
                    })
                    .catch((error) => {
                        console.error(error);
                    });

                // Remove the annotation for the deleted shape
                // setAnnotations(annotations.filter(a => a.shapeId !== shapeId));
                annotations.current = annotations.current.filter(a => a.shapeId !== shapeId);

            });

            setShowForm(false);

        });

        const nodesLayer = L.geoJSON(nodes, {
            pointToLayer: function (feature, latlng) {
                const marker = L.circleMarker(latlng, {
                    radius: 1.5,
                    fillColor: 'black',
                    color: 'black',
                    fillOpacity: 1
                });
                marker.feature = feature;
                return marker;
            },
        }).addTo(map);

        // const edgesLayer = L.geoJSON(edges, {
        //     style: function (feature) {
        //         return {
        //             color: 'black',
        //             weight: 2 // Change the weight (thickness) here
        //         };
        //     }
        // }).addTo(map);

        return () => {
            map.remove();
        };

    }, [selectedCity, cityCenter, nodes, initialShapes]); //[selectedCity, cityCenter, nodes, edges, shapes]);

    const handleCityChange = (event) => {
        setSelectedCity(event.target.value);
    };

    const handleSubmit = async (event) => {

        event.preventDefault();

        const selectedNodes = getSelectedNodes(selectedRectangle);

        const shapeId = selectedRectangle.toGeoJSON().id;
        const annotationIndex = annotations.current.findIndex(a => a.shapeId === shapeId);
        const newAnnotations = [...annotations.current];

        if (annotationIndex !== -1) {
            newAnnotations[annotationIndex] = { ...newAnnotations[annotationIndex], value: annotationValue };
        } else {
            newAnnotations.push({ shapeId: shapeId, value: annotationValue });
        }

        await axios.post(`/annotations/${selectedCity}`, {
            nodes: selectedNodes,
            value: annotationValue,
            shapeId: shapeId,
        });


        // Assume `annotationId` is available from your annotation logic
        const annotationId = shapeId; // This should be dynamically set based on your application's flow
        axios.post('/submitAnnotation', { annotationId, details: formData })
            .then(response => {
                // Handle response
                console.log('Form submitted successfully');
            })
            .catch(error => {
                // Handle error
                console.error('Error submitting form', error);
            });

        annotations.current = newAnnotations;
        // const popupContent = `<b>Value of Region:</b> ${annotationValue}`;
        // selectedRectangle.bindPopup(popupContent).openPopup();
        setSelectedRectangle(null);
        setAnnotationValue('');
        setShowForm(false);
    };

    const getSelectedNodes = (layer) => {
        const bounds = layer.getBounds();
        const selectedNodes = nodes.filter((node) => {
            const latlng = L.latLng(node.geometry.coordinates[1], node.geometry.coordinates[0]);
            return bounds.contains(latlng);
        });
        return selectedNodes.map((node) => node.properties.osmid);
    };

    const handleAnnotationValueChange = (event) => {
        setAnnotationValue(event.target.value);
    };

    // Helper function to generate hour options
    const generateHourOptions = () => {
        let hours = [];
        for (let i = 0; i <= 24; i++) {
            hours.push(<MenuItem key={i} value={i}>{i}</MenuItem>);
        }
        return hours;
    };

    // Function to add a new row to the form
    const addRow = () => {
        setFormData([...formData, { dayType: '', startTime: 0, endTime: 0, value: 0, RowIndex: formData.length }]);
    };

    return (
        <div style={{ display: 'flex', height: '100vh' }}>
            <Box sx={{ width: '70%', height: '90%', paddingTop: '15px' }}>
                <Select
                    value={selectedCity}
                    onChange={handleCityChange}
                    displayEmpty
                    sx={{ width: 200, marginBottom: 2 }} // Adjust styling as needed
                    inputProps={{ 'aria-label': 'Without label' }}
                >
                    <MenuItem value="" disabled>Select a City</MenuItem>
                    {cities.map(city => (
                        <MenuItem key={city} value={city}>{city}</MenuItem>
                    ))}
                </Select>
                <div id="map" style={{ height: '70%', width: '100%' }} ref={mapRef}></div>
                <ScoreTable /> {/* This line adds the Score Table below the map */}
            </Box>
            {showForm && (
                
                <Box sx={{ width: '30%', padding: 1.5 , paddingTop: '80px'}}>
                    <form onSubmit={handleSubmit}>
                        {formData.map((row, index) => (
                            <Grid container spacing={2} alignItems="center" key={index} sx={{ marginBottom: 2 }}> {/* Added spacing and margin for extra space */}
                                <Grid item xs={3.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Day Type</InputLabel>
                                        <Select
                                            value={row.dayType}
                                            onChange={(e) => handleFormDataChange(index, 'dayType', e.target.value)}
                                            label="Day Type"
                                        >
                                            <MenuItem value="Weekday">Weekday</MenuItem>
                                            <MenuItem value="Weekend">Weekend</MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Start Hour</InputLabel>
                                        <Select
                                            value={row.startTime}
                                            onChange={(e) => handleFormDataChange(index, 'startTime', e.target.value)}
                                            label="Start Hour"
                                        >
                                            {generateHourOptions()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2.5}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>End Hour</InputLabel>
                                        <Select
                                            value={row.endTime}
                                            onChange={(e) => handleFormDataChange(index, 'endTime', e.target.value)}
                                            label="End Hour"
                                        >
                                            {generateHourOptions()}
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={3}>
                                    <FormControl fullWidth size="small">
                                        <InputLabel>Value</InputLabel>
                                        <Select
                                            value={row.value}
                                            onChange={(e) => handleFormDataChange(index, 'value', e.target.value)}
                                            label="Value"
                                        >
                                            {Array.from({ length: 101 }, (_, i) => (
                                                <MenuItem key={i} value={i}>{i}</MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                </Grid>
                            </Grid>
                        ))}
                        <Button type="submit" variant="contained" sx={{ mt: 2, mr: 1 }}>Submit</Button>
                        <Button onClick={addRow} variant="contained" sx={{ mt: 2 }}>Add Row</Button>
                    </form>
                </Box>
            )}
        </div>
    );
}

export default AnnotationInterface;